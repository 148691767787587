import loadable from '@loadable/component'
import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import createDownloadObject from '../utils/createDownloadObject'

const Hero = loadable(() => import(`../components/Hero`))
const ImpactModule = loadable(() => import(`../components/Impact`))
const KeyAreas = loadable(() => import(`../components/KeyAreas`))
const Layout = loadable(() => import(`../components/Layout`))
const PageBody = loadable(() => import(`../components/PageBody`))
const ProgramOverview = loadable(() => import(`../components/ProgramOverview`))
const RelatedSolutions = loadable(
  () => import(`../components/RelatedSolutions`),
)
const StepForm = loadable(() => import(`../components/StepForm`))
const TrainstopSection = loadable(
  () => import(`../components/TrainstopSection`),
)

const SalesTrainingProgramPage = (
  props: PageProps<Queries.salesTrainingPageQuery>,
) => {
  const pageData = props?.data?.pageData
  const program = pageData?.program?.[0]
  const pageResources = props?.data?.pageResources?.nodes?.[0]
  const solutionsContent = props?.data?.solutionsCarousel?.nodes?.[0]

  const parentPage = props?.data?.parentCategoryPage?.nodes?.[0]
  const grandparentPage = props?.data?.grandparentCategoryPage?.nodes?.[0]
  const breadcrumbLinks = [{ text: pageData?.title || '', target: '' }]

  if (parentPage?.title && parentPage?.url) {
    breadcrumbLinks.unshift({
      text: parentPage?.title,
      target: parentPage?.url,
    })
  }

  if (grandparentPage?.title && grandparentPage?.url) {
    breadcrumbLinks.unshift({
      text: grandparentPage?.title,
      target: grandparentPage?.url,
    })
  }

  const iconCopySet = [
    {
      id: 'icon-1-for-who',
      icon: pageResources?.sales_training_program_for_who_asset,
      subhead:
        pageResources?.sales_training_program_for_who_copy?.[0]?.copy || '',
      body: program?.roles?.map((role: any) => role.title) || '',
    },
    {
      id: 'icon-2-formats',
      icon: pageResources?.sales_training_program_format_asset,
      subhead:
        pageResources?.sales_training_program_format_copy?.[0]?.copy || '',
      body: program?.formats?.map((format: any) => format.title) || '',
    },
    {
      id: 'icon-3-duration',
      icon: pageResources?.sales_training_program_duration_asset,
      subhead:
        pageResources?.sales_training_program_duration_copy?.[0]?.copy || '',
      body: [program?.duration],
    },
  ]

  const resourceDownloadUrl =
    props?.data?.downloadResourceUrl?.nodes?.[0]?.pageUrl || '/'

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  const downloadResourceObject = createDownloadObject(props, 'props')

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={props.pageContext.slug}
      title={metaTitle}
    >
      <Hero content={pageData?.page_header?.[0]} />
      {program ? (
        <ProgramOverview
          content={{
            headline: program?.overview_headline,
            body: program?.overview_description,
            ctaText:
              pageResources?.sales_training_program_download_brochure_copy?.[0]
                ?.copy,
            ctaTarget: resourceDownloadUrl,
            iconCopySet,
          }}
        />
      ) : null}
      <PageBody
        content={pageData?.page_body?.[0]}
        downloadResource={downloadResourceObject}
        downloadUrl={resourceDownloadUrl}
      />
      {pageData?.key_areas?.[0] ? (
        <KeyAreas content={pageData.key_areas?.[0]} />
      ) : null}
      {pageData?.optional_sections?.map((section: any) => (
        <React.Fragment key={section.id}>
          {section.internal.type === 'Contentstack_section_impact_stats' && (
            <ImpactModule content={section} />
          )}
          {section.internal.type === 'Contentstack_section_train_stop' && (
            <TrainstopSection
              headline={section.headline}
              slides={section.slides}
            />
          )}
        </React.Fragment>
      ))}
      {pageData?.contact_us_form?.[0]?.variant === 'Step' && (
        <StepForm formData={pageData.contact_us_form?.[0]} />
      )}
      <RelatedSolutions relatedSolutions={solutionsContent} />
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.salesTrainingPageQuery>) => (
  <>
    <SchemaMarkup data={data.pageData?.schema_markup} />
    <SchemaMarkupBreadcrumb
      grandParent={data?.grandparentCategoryPage?.nodes?.[0]}
      pageTitle={data?.pageData?.title}
      parent={{
        title: data?.parentCategoryPage?.nodes?.[0]?.title,
        pageUrl: data?.parentCategoryPage?.nodes?.[0]?.url,
      }}
    />
  </>
)

export default SalesTrainingProgramPage

export const data = graphql`
  query salesTrainingPage(
    $pageId: String
    $resourceId: String
    $locale: String
    $downloadResourceId: String
    $parentCategoryId: String
    $grandparentCategoryId: String
  ) {
    pageData: contentstackPageSalesTrainingProgram(id: { eq: $pageId }) {
      id
      pageUrl: url
      locale
      chinese_version_url
      publish_details {
        locale
      }
      title
      metadata {
        description
        page_title
        image {
          description
          url
        }
      }
      page_header {
        ...PageHeaderSection
      }
      page_body {
        ...PageBodySection
      }
      key_areas {
        ...KeyAreasSection
      }
      schema_markup {
        ...SchemaMarkup
      }
      program {
        title
        headline
        subheading
        overview_headline
        overview_description
        roles {
          title
          id
        }
        formats {
          id
          headline
          title
        }
        duration
      }
      optional_sections {
        ... on Contentstack_section_impact_stats {
          ...ImpactStatsSection
        }
        ... on Contentstack_section_train_stop {
          ...TrainStopSection
        }
      }
      contact_us_form {
        ...LeadGenForm
      }
    }

    parentCategoryPage: allContentstackPageJobSpecificPrograms(
      filter: {
        publish_details: { locale: { eq: $locale } }
        reference: { elemMatch: { id: { eq: $parentCategoryId } } }
      }
    ) {
      nodes {
        title
        url
      }
    }

    grandparentCategoryPage: allContentstackPageProductOverview(
      filter: {
        publish_details: { locale: { eq: $locale } }
        product_category: { elemMatch: { id: { eq: $grandparentCategoryId } } }
      }
    ) {
      nodes {
        title
        url
      }
    }

    pageResources: allContentstackResourcesWebsite(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        download_card_cta_text
        sales_training_program_format_copy {
          copy
        }
        sales_training_program_format_asset {
          description
          imgixImage {
            gatsbyImageData(imgixParams: { q: 65 })
          }
        }
        sales_training_program_for_who_copy {
          copy
        }
        sales_training_program_for_who_asset {
          description
          imgixImage {
            gatsbyImageData(imgixParams: { q: 65 })
          }
        }
        sales_training_program_duration_copy {
          copy
        }
        sales_training_program_duration_asset {
          description
          imgixImage {
            gatsbyImageData(imgixParams: { q: 65 })
          }
        }
        sales_training_program_download_copy {
          copy
        }
        sales_training_program_download_brochure_copy {
          copy
        }
        impact_module_asset_dark {
          description
          imgixImage {
            gatsbyImageData(imgixParams: { q: 65 })
          }
        }
      }
    }

    downloadResourceUrl: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { id: { eq: $resourceId } } }
      }
    ) {
      nodes {
        pageUrl: url
        resource {
          id
        }
      }
    }

    solutionsCarousel: allContentstackSectionSolutionsCardCarousel(
      filter: {
        title: { eq: "Solutions Card Carousel - Global" }
        publish_details: { locale: { eq: $locale } }
      }
    ) {
      nodes {
        ...FeaturedProgramsSection
      }
    }

    downloadResourceCard: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: {
            is_gated: { eq: "Yes" }
            id: { eq: $downloadResourceId }
          }
        }
      }
    ) {
      nodes {
        title
        url
        resource {
          headline
          side_cta_short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 374, imgixParams: { q: 60 })
            }
          }
        }
      }
    }
  }
`
